body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f5f8fa !important;
}
@media (prefers-color-scheme: dark) {
	body {
		background-color: #293742 !important;
	}
}
.bp3-overlay {
	z-index: 999 !important;
}
.bp3-overlay-content {
	z-index: 999 !important;
}
.bp3-transition-container {
	z-index: 999 !important;
}
